$hamburger-color: $white-1;

#navigationContainer {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: map-get($z-index, header);

    .navbar-container {
        background-color: $purple-2;
        box-shadow: 2px 2px 0 0 rgb($purple-1, 12%);

        ul.navbar {
            padding: 0;
            margin-top: 0;
            display: flex;
            justify-content: center;
            font-size: 1.15rem;
            list-style: none;

            li {
                padding: 0.25rem 0.5rem;
                margin: auto 0;

                a:not(.button-link) {
                    text-decoration: none;
                    color: $text-color;
                    padding: 0.5rem 1rem;
                    letter-spacing: 3px;
                    font-weight: bold;
                    text-transform: uppercase;
                    text-shadow: 0px 0px $teal-1;
                    transition: text-shadow 0.2s ease-in-out;

                    &:hover {
                        text-shadow: 6px -6px $teal-1;
                        transition: text-shadow 0.2s ease-in-out;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 993px) {
    #navigationContainer {
        .hamburger-container {
            display: none; 
        }

        .mobile-title {
            display: none;
        }
    }
}

@media only screen and (max-width: 992px) {
    #navigationContainer {
        background-color: $purple-2;
        height: 48px;

        .unicorn-right {
            display: none;
        }

        .hamburger-container {
            height: calc(100% - 10px);
            width: 45px;
            padding: 5px 0;
            margin: 0 0 0 auto;
            display: flex;
            cursor: pointer;

            .hamburger {
                width: 30px;
                height: 30px;
                position: relative;
                transition: .1s;
                margin: auto;
                z-index: map-get($z-index, header);
            
                span {
                    width: 5px;
                    height: 5px;
                    background-color: $hamburger-color;
                    display: block;
                    position: absolute;
                }
            
                &:hover span{
                    transform: scale(1.2);
                    transition: 350ms cubic-bezier(.8, .5, .2, 1.4);
                }
            
                span:nth-child(1){
                    left: 0;
                    top: 0;
                }
            
                span:nth-child(2){
                    left: 12px;
                    top: 0;
                }
            
                span:nth-child(3){
                    right: 0.5px;
                    top: 0;
                }
            
                span:nth-child(4){
                    left: 0;
                    top: 12px;
                }
            
                span:nth-child(5){
                    position: absolute;
                    left: 12px;
                    top: 12px;
                }
            
                span:nth-child(6){
                    right: 0.5px;
                    top: 12px;
                }
            
                span:nth-child(7){
                    left: 0px;
                    bottom: 0px;
                }
            
                span:nth-child(8){
                    position: absolute;
                    left: 12px;
                    bottom: 0px;
                }
            
                span:nth-child(9){
                    right: 0.5px;
                    bottom: 0px;
                }
            
                &.open{
                    transform: rotate(180deg);
                    cursor: pointer;
                    transition: .2s cubic-bezier(.8, .5, .2, 1.4);
                }
            
                &.open span{
                    transition-delay: 200ms;
                    transition: .5s cubic-bezier(.8, .5, .2, 1.4);
                }
            
                &.open span:nth-child(2) {
                    left: 6px;
                    top: 6px;
                }
            
                &.open span:nth-child(4) {
                    left: 6px;
                    top: 18px;
                }
            
                &.open span:nth-child(6) {
                    right: 6px;
                    top: 6px;
                }
            
                &.open span:nth-child(8) {
                    left: 18px;
                    bottom: 6px;
                }
            }
        }

        .mobile-title {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: map-get($z-index, header);
            white-space: nowrap;
            
            p {
                margin: 0;

                a {
                    color: $white-1;
                    text-shadow: 1px 2px $teal-1;
                    text-decoration: none;
                }
            }
        }

        .navbar-container {
            background-color: $purple-2;
            width: 100%;
            position: absolute;
            z-index: map-get($z-index, mobile-navigation);
            height: 100vh;
            left: -100vw;
            transition: left 0.2s linear;
            overflow-y: scroll;

            &.show { 
                left: 0;
                transition: left 0.2s linear;
            }

            ul.navbar {
                height: auto;
                min-height: 100vh;
                padding: 25px 0 100px;
                flex-direction: column;
                justify-content: flex-start;
                font-size: 1.25rem;
                text-align: center;
                overflow-x: hidden;
                -webkit-overflow-scrolling: touch;

                li {
                    margin: 1rem 0;
                    padding: 0;

                    &.header-wallet {
                        order: -1;
                    }

                    a:not(.button-link) {
                        display: inline-block;
                        width: 100%;
                        padding: 1rem 0;
                        color: $white-1;
                    }
                }
            }
        }
    }
}  
