@import "./scss/common.scss";
@import "./scss/landing.scss";
@import "./scss/navigation.scss";
// Rainbow design vector created by rawpixel.com

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  color: $text-color;
  background-color: $white-1;
  z-index: map-get($z-index, fixed-body);

  &.disable-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

// Direct component styling
a {
  color: $purple-2;

  &.anchor-target {
    opacity: 0;
    display: inline-block;
    background-color: transparent;
    height: 0;
  }
}

p {
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0.5rem 0;
  letter-spacing: 1px;
}

h1,
p.h1 {
  padding: 0.4em 1em;
  margin: 0;
  font-size: 152px;
  letter-spacing: 5px;
  white-space: nowrap;
  text-shadow: 2px 2px $purple-1;
  color: $purple-2;
  font-weight: normal;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
}

h2,
h3 {
  color: $text-color;
  letter-spacing: 1px;
  text-shadow: 1px 1px $purple-1;
}

/* From cssbuttons.io */
button {
  margin: auto;
  background-image: linear-gradient(144deg,#AF40FF, $purple-2 50%, $teal-2);
  border: 0;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: $purple-1;
  display: flex;
  font-size: 20px;
  line-height: 1em;
  padding: 3px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all .2s;

  &:active,
  &:hover {
    outline: 0;
    color: $white-1;
  }
  
  span:not(.additional) {
    background-color: $white-1;
    padding: 10px 24px;
    width: 100%;
    height: 100%;
    transition: 200ms;
  }
  
  &:hover span {
    background: none !important;
  }
  
  &:active {
    transform: scale(0.9);
  }

  &.dapp-button {
    min-width: 140px;
    letter-spacing: 2px;
  }

  &.wallet-button {
    min-width: 140px;
    letter-spacing: 2px;
    color: $white-1;
  
    span {
      background-color: black;
    }
  }
  
  &.connected-wallet-button {
    font-size: 1rem;
    letter-spacing: 1px;
    background-image: none;
    color: $white-1;
    padding: 2px;
    pointer-events: none;
  
    span {
      background-color: $purple-2;
    }
  
    &:hover {
      color: $purple-1;
    }
  }  
}

span {
  &.clickable-span {
    cursor: pointer;
    position: relative;
    text-decoration: underline;
    // background-color: $teal-1;
  }
}

.emphasize {
  color: $teal-1;
}

.hidden {
  display: none;
}

.column-2 {
  padding: 50px 0;
  display: flex;

  .padding {
    padding: 0 5%;
  }
}

#mainDiv {
  padding-bottom: 50px;

  h1,
  h2,
  h3,
  p,
  span,
  a,
  div {
    font-family: 'VT323', 'Helvetica', sans-serif;
  }

  &.disable-pixel-font {
    h1,
    h2,
    h3,
    p,
    span,
    a,
    div {
      font-family: 'Helvetica', sans-serif;
    }
  }

  // &.popup-open {
  //     bottom: 0;
  //     left: 0;
  //     position: fixed;
  //     top: 0;
  //     width: 100%;
  //     z-index: map-get($z-index, modal);
  //     overflow: hidden;
  // }
}

#mainContentContainer {
  z-index: map-get($z-index, content);
  position: relative;
  overflow-x: hidden !important;

  > section {
    &:not(.full-width, .empty-section) {
      padding: 3% 5%;
      max-width: $component-max-width;
      margin-right: auto;
    }

    &.empty-section {
      height: 50px;
    }
  }
}

.rainbow-container {
  height: 100vh;

  &.flipped {
    transform: scaleX(-1);
  }
}

#footerContainer {
  margin-top: 200px !important;
}

.landing-button-container {
  display: flex;
  justify-content: center;
  gap: 8px
}

@media only screen and (min-width: 993px) {
  html {
    scroll-behavior: smooth;
  }
}

@media only screen and (max-width: 992px) {
  #mainContentContainer {
    > section {
      text-align: center;
    }
  }

  .column-2 {
    flex-direction: column;
  }

  .rainbow-container {
    height: 60vh;
  }
}

@media only screen and (max-width: 768px) {
  #mainContentContainer {
    > section {
      &:not(.full-width) {
        padding: 0 15px;
        margin: 0 auto;
      }
    }
  }

  h1,
  p.h1 {
    font-size: 96px;
  }

  .landing-button-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    button,
    a {
      margin: auto !important ;
    }
    
  }
}

@media only screen and (max-width: 544px) {
  h1,
  p.h1 {
    font-size: 72px;
  }
}

.button-link {
  text-decoration: none;
}

.popup-container {
  position: fixed;
  display: none;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  background-color: #55555555;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: map-get($z-index, popup);
  height: 100vh;
  width: 100vw;

  &.open{
      display: flex;
  }

  .popup {
      min-width: 0;
      min-height: 0;
      padding: 0;
      width: auto;
      height: auto;
      color: $white-1;
      position: relative;
      // max-width: 400px;

      .body {
          // padding: 50px;
      }

      a {
          color: $white-1;
          text-decoration: none;
      }

      .popup-content {
          padding: 0;
          margin: 0;
          box-shadow: 0px 0 9px 2px $purple-2;
      }

      .header {
          .close-button {
              position: absolute;
              top: -50px;
              right: -8px;
              background: transparent;
          }
      }

      img {
          // max-width: 60vw;
          // max-height: 60vh;
          max-width: 75vw;
          max-height: 75vh;

          &.mobile-image {
              display: none;
          }

          @media screen and (max-width: 596px) {
              max-width: 90vw;
              max-height: 90vh;

              &.mobile-image {
              display: block;
              }

              &.desktop-image {
              display: none;
              }
          }
      }

      button.popup-button {
        position: absolute;
        bottom: -120px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        padding: 5px;
        margin: auto 0;
        line-height: 1.5em;
        font-size: 24px;

        .additional {
          padding-left: 15px;
          font-size: 32px;
          vertical-align: middle;
        }

        .flip {
          display: inline-block;
          transform: scaleX(-1);
        }
      }
  }
}
