@import "./common.scss";
@import "./spinner.scss";

$border-color: rgba($white-2, 0.8);
$background-color: $purple-3;

#tokenContainer {
  background-color: $background-color;
  color: $white-2;
  min-height: 100vh;
  font-family: 'Roboto', 'Helvetica', sans-serif;

  h1 {
    font-size: 8vw;
    padding: 0.2em 0;
    text-align: center;
    color: $white-1;
    font-family: 'VT323', 'Helvetica', sans-serif;
    white-space: unset;
  }

  h2,
  h3,
  h4 {
    text-align: center;
    font-family: 'VT323', 'Helvetica', sans-serif;
  }

  h4 {
    font-size: 1.5rem;
    margin: 1rem 0;
    font-weight: normal;
  }

  .page-container {
    margin: 0 auto;
    padding: 0 5%;
    max-width: 1500px;
  }

  .token-details-container {
    display: flex;
    margin: 0 -15px;
    padding: 0 15px;
    flex: 1;
  }

  // $image-padding: 10px;

  .media-block {
    width: 100%;
    max-width: 40%;
    margin: 10px auto 0;
    position: sticky;
    top: 5px;
    align-self: flex-start;

    .image-container {
      // border: 2px solid $border-color;
      // padding: $image-padding;
      max-width: 600px;
      // box-shadow: 0px 0px 30px 1px rgba($border-color, 0.20);
      transition: all .3s;
      position: relative;
      cursor: pointer;
      border-radius: 8px;

      &:hover {
        img {
          box-shadow: 0px 0px 20px 1px rgba($border-color, 0.50);
        }

        // img.main {
        //   filter: grayscale(100%);
        // }
      }

      img {
        width: 100%;
        transition: all .3s;
        border-radius: 8px;
        box-shadow: 0px 0px 20px 1px rgba($border-color, 0.20);

        // &.accessory {
        //   width: calc(100% - $image-padding * 2);
        //   position: absolute;
        //   left: 10px;
        //   top: 9px;
        //   transform: scaleX(-1) translateY(-20%);
        //   border-color: transparent;
        // }
      }
    }
  }

  .attributes-block {
    width: 100%;
    height: 100%;

    .attributes-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-left: 15px;
      width: 100%;
    }

    .attribute-item {
      // flex-basis: 0;
      // -webkit-box-flex: 1;
      // flex-grow: 1;
      // box-sizing: border-box;
      width: 100%;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;

      .attribute-item-content {
        height: 125px;
        padding: 5px;
        margin: 5px;
      }

      p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 0 auto;
        font-family: 'Roboto';
        max-width: 90%;
      }

      .trait-type {
        font-size: 16px;
        color: $teal-2;
      }

      .trait-value {
        font-size: 16px;
      }

      .rarity {
        font-size: 14px;
      }
    }

    .card {
      height: 100%;
      background-image: linear-gradient(163deg, $border-color 0%, $blue-1 100%);
      border-radius: 8px;
      transition: all .3s;
      text-align: center;
      cursor: pointer;
      box-shadow: 0px 0px 20px 1px rgba($border-color, 0.10);

      &:hover {
        box-shadow: 0px 0px 20px 1px rgba($border-color, 0.30);
        border-radius: 20px;
      }

      .card2 {
        width: 100%;
        height: 100%;
        background-color: $background-color;
        transition: all .2s;
        border: 2px solid $border-color;
        border-radius: 8px;

        &:hover {
          border-radius: 20px;
        }

        .card-body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          position: relative;
        }
      }
    }
  }

  .reveal-container {
    text-align: center;

    img {
      max-width: 400px;
    }
  }

  .links-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 40px 0;

    a {
      color: $white-1;
      font-size: 24px;
      margin-left: 40px;

      &:hover {
        color: $border-color;
      }
    }
  }

  .wallet-button-container {
    position: relative;
    top: 10px;
    margin: 0 -12px 15px;
    display: flex;
    justify-content: center;

    button {
      margin-left: 15px;
      margin-right: 15px;
      line-height: 20px;
      border-radius: 8px;
      font-size: 14px;
      box-shadow: none;

      span {
        border-radius: 8px;
      }

      &.wallet-button {
        background-image: linear-gradient(144deg,$teal-1, $purple-2);
      }

      &.connected-wallet-button {
        background: transparent;
      }
    }

    .wallet-button-authenticated-container {
      display: flex;
    }
  }

  #status { 
    text-align: right;
    font-size: 1.2rem;
    word-wrap: break-word;
  }

  .owned-nfts-container {
    margin-top: 100px;

    .owned-nfts {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      a {
        padding: 15px;
        text-decoration: none;

        &:hover {
          img {
            box-shadow: 0px 0px 30px 1px rgba($border-color, 0.30);
          }
        }
      }

      p {
        text-align: center;
        color: $white-1;
        font-size: 20px;
        margin: 0;
      }

      img {
        height: 150px;
        width: 150px;
        border-radius: 8px;
      }
    }
  }

  .bottom-message {
    margin-top: 30px;

    p {
      font-size: 1.5rem;
      margin: 1rem 0;
      font-weight: normal;
      text-align: center;
    }
  }

  @media only screen and (min-width: 1500px) {
    .attributes-block {
      .attribute-item {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }

  @media only screen and (min-width: 769px) {
    .wallet-button-container {
      right: -5px;
      justify-content: flex-end;
    }
  }

  @media only screen and (max-width: 1200px) {
    .attributes-block {
      .attribute-item {
        .trait-type {
          font-size: 14px;
        }

        .trait-value {
          font-size: 16px ;
        }

        .rarity {
          font-size: 14px;
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .attributes-block {
      .attribute-item {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .token-details-container {
      flex-direction: column;
    }

    .media-block {
      width: 90%;
      max-width: none;
      margin: 20px auto;
      position: static;
    }

    .attributes-block {
      width: calc(90% + 20px);
      max-width: none;
      margin: 20px auto;

      .attributes-container {
        justify-content: space-between;
        margin-left: 0;
      }

      .attribute-item {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
    }

    .wallet-button-container {
      flex-direction: column;
      margin: 0 0 25px;

      .connected-wallet-button {
        margin-top: 15px;
      }
    }

    .wallet-button-authenticated-container {
      flex-direction: column;
    }

    #status { 
      text-align: center;
    }

    .owned-nfts-container { 
      .owned-nfts {
        justify-content: space-around;
      }
    }
  }

  @media only screen and (max-width: 544px) {
    h1 {
      font-size: 10vw;
    }

    .attributes-block {
      .attribute-item {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}