
#landingContainer {
  text-align: center;
  padding: 0;
  min-height: 350px;
  position: relative;
  z-index: map-get($z-index, landing);
  background: linear-gradient(180deg, $blue-1 0%, $blue-2 100%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .landing-parallax-container {
    position: relative;
    height: 100vh;
    z-index: map-get($z-index, landing);
  }

  .landing-top-container {
    display: flex;
    align-items: center;
    height: 80vh;
  }

  .title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
      display: none;
    }

    p.h1 {
      line-height: normal;
      padding: 0.4em 0 0.4em 0.5em;
    }

    span.title {
      margin: 3rem 0;
      display: inline-block;
      opacity: 0;
      animation: reveal-title 1s linear forwards;
      animation-delay: 0.5s;
    }
  }

  .landing-button {
    width: 400px;
    padding: 5px;
    margin: auto 0;
    line-height: 1.5em;
    font-size: 24px;

    .additional {
      padding-left: 15px;
      font-size: 32px;
      vertical-align: middle;
    }

    .flip {
      display: inline-block;
      transform: scaleX(-1);
    }
  }
}

@media only screen and (max-width: 768px) {
  #landingContainer {
    .landing-top-container {
      flex-direction: column;
      align-items: center;
    }

    .title-container {
      padding: 50px 0 0;

      p.h1 {
        padding: 0.1em 0.5em;
      }
    }
  }
}

@keyframes reveal-title {
  0% {
    opacity: 0;
    color: $blue-1;
  }
  50% {
    color: $blue-1;
  }
  100% {
    color: $purple-2;
    opacity: 1;
  }
}
