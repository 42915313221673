// project variables
$purple-1: #22162b;
$purple-2: #432fcf;
$purple-3: rgb(21, 14, 24);
$white-1: #efefef;
$white-2: #d8f4fd;
$teal-1: #1fbcd3;
$teal-2: #24e3ff;
$blue-1: #1f8cf1;
$blue-2: #aeeaff;
$red-1: #bf1813;
$yellow-1: #fdbe2f;
$component-max-width: 1500px;
$mint-button-shadow: darken($teal-1, 10%);
$text-color: $white-1;

$z-index: (
    popup: 8,
    spinner: 7,
    header: 6,
    mobile-navigation: 5,
    celebration: 4,
    fixed-body: 3,
    landing: 2,
    content: 1,
    zero: 0,
    negative: -1,
);
