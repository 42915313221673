$topOffsetCoefficient: 2vh;
$leftOffsetCoefficient: 20vw;

.pixel-unicorn-container {
  margin-left: auto;
  right: 0;

  &.complete .pixel-unicorn * {
    top: 0 !important;
    left: 0 !important;
    border-color: transparent !important;
  }

  .pixel-unicorn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    position: relative;

    .row {
      display: flex;
      justify-content: center;

      @for $i from 1 through 7 {
        &.row-#{$i} {
          > div > div {
            top: calc(-100vh + calc($i * $topOffsetCoefficient));
          }
        }
      }

      @for $i from 8 through 14 {
        &.row-#{$i} {
          > div > div {
            top: calc(100vh + calc($i * $topOffsetCoefficient));
          }
        }
      }

      > div {
        height: 25px;
        width: 25px;
        position: relative;

        @for $i from 1 through 7 {
          &:nth-child(#{$i}) {
            > div {
              left: calc(-100vw + calc(calc($i - 8) * $leftOffsetCoefficient * $i));
            } 
          }
        }

        @for $i from 8 through 14 {
          &:nth-child(#{$i}) {
            > div {
              left: calc(100vw + calc($leftOffsetCoefficient * calc($i - 7)));
            }
          }
        }

        // @for $i from 1 through 14 {
        //   &:nth-child(#{$i}) {
        //     > div {
        //       transition: top calc($i * 0.1s) ease-out, left calc($i * 0.1s)  ease-out;
        //     } 
        //   }
        // }

        > div:not(.transparent) {
          position: absolute;
          width: 100%;
          height: 100%;
          will-change: left, top;
          $baseTransition: 0.55s;
          $differenceTransition: 0.25s;
          border: 1px solid black;
          z-index: 1; // map-get($z-index, landing);

          &.body {
            background-color: #f1f1f1;
            transition: top $baseTransition ease-out, left $baseTransition ease-out, border-color 1s + $baseTransition ease-out;
          }

          &.eye {
            background-color: rgba(33, 150, 243, 1);
            $eyeTransition: $baseTransition + $differenceTransition * 1;
            transition: top $eyeTransition ease-out, left $eyeTransition ease-out, border-color 1s + $eyeTransition ease-out;
            cursor: pointer;

            .eyeImage {
              height: 100%;
              width: 100%;
              opacity: 0;
              animation: reveal 7s infinite;
              -webkit-animation: reveal 7s infinite;
              -moz-animation: reveal 7s infinite;
              -o-animation: reveal 7s infinite;
            }

            &.show {
              .eyeImage {
                animation: none;
                -webkit-animation: none;
                -moz-animation: none;
                -o-animation: none;
                opacity: 1;
                // transition: all 2s ease-in-out;
                // height: 300px;
                // width: 300px;
              }
            }
          }

          &.hair1 {
            background-color: #fd4e67;
            background-color: #8ac881;
            background-color: rgba(103, 58, 183, 1);
            $hair1Transition: $baseTransition + $differenceTransition * 2;
            transition: top $hair1Transition ease-out, left $hair1Transition ease-out, border-color 1s + $hair1Transition ease-out;
          }

          &.hair2 {
            background-color: #fd9657;
            background-color: rgba(156, 39, 176, 1);
            $hair2Transition: $baseTransition + $differenceTransition * 3;
            transition: top $hair2Transition ease-out, left $hair2Transition ease-out, border-color 1s + $hair2Transition ease-out;
          }

          &.horn {
            background-color: rgba(255, 193, 7, 1);
            $hornTransition: $baseTransition + $differenceTransition * 4;
            transition: top $hornTransition ease-out, left $hornTransition ease-out, border-color 1s + $hornTransition ease-out;
          }
        }
      }
    }
  }
}

@-webkit-keyframes reveal {
  0% {
    opacity: 0; 
  }
  98% {
    opacity: 0; 
  }
  99% {
    opacity: 1; 
  }
  100% {
    opacity: 0; 
  }
}

@keyframes reveal {
  0% {
    opacity: 0; 
  }
  98% {
    opacity: 0; 
  }
  99% {
    opacity: 1; 
  }
  100% {
    opacity: 0; 
  }
}